import React from "react";
import {
    ThemeProvider,
    CssBaseline,
    Typography,
    Button,
    Stack
} from "@mui/material";
import { Container, Box } from "@mui/system";
import theme from "../theme/theme";
import CustomizedInputs from "../components/customComponent/CustomInput";
import constant from "../constants/Constatnt";
import { AuthContext } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { appTheme } from "../theme/colorTheme";

const WalletDepositScreen = () => {
    const { setPaymentData } = React.useContext(AuthContext);
    let navigate = useNavigate();
    const minDepositCoins = useSelector(store => store?.app?.commonInfo?.min_deposit_wallet);
    const { wallet_balance } = useSelector(store => store?.app?.userInfo);
    const [depositCoin, setDepositCoin] = React.useState("");
    const [isDepositCoinValid, setIsDepositCoinValid] = React.useState(true);
    const [errorMsg, setErrorMsg] = React.useState("");

    const handleOpenDialog = (isDepositCoinValidCheck) => {
        if (isDepositCoinValidCheck) {
            setPaymentData({
                payment_method_amount: +depositCoin,
                requested_amount: +depositCoin,
                transactionFor: constant.transactionFor.walletDeposit
            })
            navigate('/payment');
        }

    };


    const checkDepositCoin = (value, isClickContinue) => {
        if (+value < minDepositCoins) {
            setErrorMsg(`Minimum deposit amount should be at least ${minDepositCoins} coins.`)
            setIsDepositCoinValid(false);
        } else {
            setIsDepositCoinValid(true);
            if (isClickContinue) {
                handleOpenDialog(true)
            }
        }
    }

    const continueToPay = (depositCoinValueCheck) => {
        checkDepositCoin(depositCoinValueCheck, true);
    }

    React.useEffect(() => {

    }, [])

    if(!minDepositCoins || (wallet_balance === undefined)) return;
    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Container
                    sx={{
                        color: appTheme.color1,
                        mt: 1
                    }}
                >
                    <Stack
                        sx={{
                            alignItems: "center",
                            justifyContent: "center",
                            textAlign: "center",
                            mb: 5,
                        }}
                    >
                        <Box
                            sx={{
                                mb: 2
                            }}
                        >
                            <img
                                width="120px"
                                src={require(`../img/deposit_icon.png`)}
                                alt="noOffer"
                                loading="lazy"
                            />
                        </Box>
                        <Typography variant="caption" display="block" sx={{mt:-4}}>
                            Wallet Balance
                        </Typography>
                        <Typography variant="h6" gutterBottom component="div">
                            ₹{wallet_balance}
                        </Typography>

                    </Stack>
                    <CustomizedInputs key={"1"} placeholder="Enter Coins"
                        label={`Coins* (Minimum deposit amount is ${minDepositCoins} coins)`}
                        type="number"
                        errorMsg={errorMsg}
                        defaultValue={depositCoin}
                        setData={setDepositCoin}
                        isValidation={isDepositCoinValid}
                        checkValidation={checkDepositCoin} />
                    <Stack spacing={2}>
                        <Button onClick={() => {
                            continueToPay(depositCoin);
                        }}
                            sx={{
                                color: appTheme.color0, bgcolor: appTheme.color12,
                                "&:hover": {
                                    bgcolor: appTheme.color12
                                },
                                focusVisible: false,
                                pb: 1, pt: 1
                            }}>Deposit Coins</Button>

                    </Stack>
                </Container>
            </ThemeProvider>
        </React.Fragment>
    );
};
const WalletDeposit = React.memo(WalletDepositScreen)

export default WalletDeposit;
